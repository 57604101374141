import * as React from 'react'
import Footer from '../../../../components/Footer/Footer'
import Gallery, { useGallery } from '../../../../components/Gallery/Gallery'
import Bounce from 'react-reveal/Bounce'
import images from '../../../../components/Gallery/ImageLists/tusk-2019/trivia-ny'

import './../tusk-2019.scss'

const Trivia = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <>
      <div id="trivia-ny">
        <section id="hero" className="hero standard wrap">
          <nav className="wrap nav">
            <div className="container cf">
              <a href="https://www.emso.com/" title="Back to Emso" id="logo">
                <img src="/images/tusk-2019/images/emso-logo.svg" alt="Emso logo" width="90" height="62" />
              </a>
            </div>
          </nav>
          <div className="page-title">
            <div className="container cf">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <h1>Emso Trivia Night New York 2019</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main id="content" className="wrap" role="main">
          <Gallery images={images} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />
          <Bounce bottom>
            <section id="safaricom-galery" className="section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                    <h6>Photo gallery</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0001.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0001.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0002.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0002.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0003.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0003.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0004.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0004.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0005.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0005.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0006.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0006.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0007.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0007.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0008.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0008.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0009.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0009.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0010.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0010.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0011.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0011.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0012.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0012.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0013.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0013.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0014.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0014.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0015.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0015.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0016.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0016.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0017.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0017.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0018.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0018.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0019.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0019.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0020.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0020.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0021.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0021.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0022.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0022.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0023.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0023.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0024.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0024.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0025.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0025.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0026.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0026.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0027.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0027.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0028.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0028.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0029.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0029.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0030.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0030.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0031.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0031.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0032.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0032.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0033.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0033.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0034.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0034.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0035.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0035.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0036.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0036.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0037.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0037.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0038.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0038.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0039.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0039.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0040.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0040.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0041.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0041.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0042.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0042.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0043.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0043.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0044.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0044.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0045.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0045.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0046.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0046.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0047.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0047.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0048.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0048.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0001.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0049.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0050.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0050.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0052.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0052.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0053.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0053.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0054.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0054.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0055.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0055.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0056.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0056.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0057.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0057.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0058.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0058.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0059.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0059.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0060.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0060.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0061.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0061.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0062.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0062.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0063.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0063.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0064.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0064.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0065.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0065.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0066.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0066.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0067.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0067.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0068.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0068.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0069.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0069.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0070.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0070.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0071.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0071.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0072.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0072.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0073.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0073.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0074.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0074.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0075.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0075.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0076.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0076.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0077.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0077.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0078.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0078.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0079.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0079.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0080.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0080.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0081.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0081.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0082.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0082.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0083.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0083.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0084.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0084.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0085.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0085.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0086.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0086.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0087.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0087.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0088.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0088.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0089.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0089.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0090.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0090.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0091.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0091.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0092.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0092.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0093.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0093.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0094.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0094.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0095.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0095.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0096.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0096.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0097.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0097.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0098.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0098.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0099.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0099.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0100.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0100.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0101.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0101.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0102.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0102.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0103.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0103.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0104.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0104.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0105.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0105.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0106.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0106.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0107.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0107.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0108.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0108.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0109.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0109.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0110.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0110.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0111.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0111.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0112.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0112.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0113.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0113.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0114.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0114.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0115.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0115.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0116.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0116.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0117.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0117.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0118.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0118.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0119.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0119.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0120.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0120.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0121.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0121.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0122.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0122.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0001.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0123.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0124.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0124.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0125.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0125.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0126.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0126.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0127.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0127.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0128.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0128.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0129.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0129.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0130.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0130.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0131.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0131.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0132.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0132.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0133.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0133.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0134.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0134.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0135.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0135.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0136.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0136.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0137.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0137.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0138.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0138.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0139.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0139.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0140.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0140.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0141.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0141.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0142.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0142.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0143.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0143.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0144.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0144.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0145.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0145.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0146.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0146.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0147.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0147.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0148.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0148.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0149.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0149.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0150.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0150.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0151.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0151.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0152.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0152.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0153.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0153.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0154.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0154.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0155.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0155.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0156.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0156.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0157.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0157.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0158.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0158.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0159.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0159.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0160.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0160.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0161.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0161.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0162.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0162.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0163.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0163.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0164.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0164.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0165.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0165.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0166.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0166.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0167.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0167.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0168.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0168.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0169.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0169.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0170.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0170.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0171.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0171.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0172.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0172.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0173.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0173.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0174.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0174.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0175.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0175.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0176.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0176.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0177.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0177.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0178.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0178.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0179.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0179.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0180.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0180.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0181.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0181.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0182.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0182.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0183.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0183.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0184.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0184.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0185.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0185.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0186.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0186.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0188.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0188.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0189.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0189.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0190.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0190.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0191.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0191.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0192.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0192.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0193.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0193.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0194.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0194.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0195.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0195.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0196.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0196.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0197.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0197.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0198.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0198.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0199.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0199.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0200.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0200.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0201.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0201.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0202.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0202.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0203.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0203.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0204.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0204.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0205.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0205.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0206.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0206.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0207.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0207.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0208.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0208.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0209.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0209.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0210.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0210.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0211.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0211.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0212.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0212.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0213.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0213.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0214.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0214.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0215.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0215.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0216.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0216.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0217.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0217.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0218.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0218.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0219.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0219.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0220.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0220.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0221.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0221.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0222.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0222.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0223.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0223.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0224.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0224.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0226.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0226.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0227.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0227.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0228.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0228.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0229.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0229.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0230.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0230.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0231.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0231.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0232.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0232.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0233.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0233.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0234.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0234.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0235.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0235.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0236.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0236.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0237.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0237.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0238.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0238.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0239.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0239.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0240.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0240.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0241.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0241.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0242.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0242.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0243.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0243.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0244.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0244.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0245.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0245.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0246.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0246.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0247.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0247.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0248.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0248.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0249.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0249.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0250.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0250.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0251.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0251.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0252.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0252.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0253.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0253.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0254.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0254.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0255.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0255.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0256.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0256.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0257.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0257.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0258.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0258.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0259.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0259.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0260.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0260.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0261.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0261.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0262.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0262.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0263.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0263.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0264.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0264.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0265.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0265.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0266.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0266.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0267.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0267.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0268.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0268.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0269.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0269.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0270.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0270.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0271.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0271.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0272.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0272.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0273.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0273.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0274.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0274.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0275.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0275.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0276.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0276.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0277.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0277.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0278.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0278.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0279.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0279.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0280.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0280.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/2019EmsoTrivia-0281.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/2019EmsoTrivia-0281.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/Thumbnail 1.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/Thumbnail 1.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/thumbnail 2.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/thumbnail 2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/nyc/large/thumbnail 3.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/nyc/small/thumbnail 3.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Bounce>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default Trivia;
